import React, { useEffect, useState } from 'react';
import api from '~/services/api';
import atob from 'atob';
import { useHistory } from 'react-router-dom';

import { IProps } from './interface/Props';

const Order: React.FC<IProps> = () => {
  const queryParams = new URLSearchParams(window.location.search);

  const products = queryParams.get('products');

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const history = useHistory();

  useEffect(() => {
    async function storeOrder() {
      try {
        if (products) {
          let productActive = false;
          const { data: dataOrder } = await api.post(`orders`);
          const orderId = dataOrder.id;

          const arrProducts = products.split(',');
          for (let i = 0; i < arrProducts.length; i += 1) {
            const product = arrProducts[i];
            const objProduct = product.split(':');

            try {
              const { data } = await api.get(`products-sku/${objProduct[0]}`);

              await api.post(`orders/products`, {
                order_id: orderId,
                product_id: data.id,
                qtd: Number(objProduct[1]),
              });
              productActive = true;
            } catch (err) {} // eslint-disable-line
          }

          if (!productActive) {
            setError('Nenhum produto encontrado');
          } else {
            const customerBase64 = queryParams.get('customer');
            if (customerBase64) {
              let customerBase64ToString = atob(customerBase64);
              customerBase64ToString = JSON.parse(customerBase64ToString);
              const { data: customerData } = await api.post(
                'customers_find_or_create',
                customerBase64ToString,
              );

              const { data: dataCustomer } = await api.post(
                `auth/buyer/email`,
                {
                  email: customerData.email,
                },
              );
              const tokenBuyer = dataCustomer.token;

              localStorage.setItem('imperio@tokenBuyer', tokenBuyer);
              localStorage.setItem('imperio@order_id', orderId);

              await api.put(
                `orders/${orderId}`,
                {},
                { headers: { tokenBuyer } },
              );

              await api.put(
                `orders/notification/${orderId}`,
                {
                  notification_id: 1,
                },
                { headers: { tokenBuyer } },
              );
            }

            history.push(`/checkout/${orderId}`);
          }
          setLoading(false);
        }
      } catch (err) {
        setError('Erro, entre em contato em nosso suporte');
        setLoading(false);
      }
    }
    storeOrder();
  }, []); // eslint-disable-line

  return (
    <div className="container page__autenticate pb-5 pt-5">
      {loading ? (
        <p className="d-block mb-5 mt-5 text-center">Criando pedido...</p>
      ) : (
        <h4 className="d-block mb-5 mt-5 text-center">{error}</h4>
      )}
    </div>
  );
};

export default Order;
