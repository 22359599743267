import React from 'react';

const Header: React.FC = () => {
  return (
    <header className="header">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="header-container align-items-center d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <a href="https://lojasimperio.com.br/">
                  <img src="/assets/img/logo.png" alt="Império" />
                </a>
                <p className="mb-0 ml-5">ambiente seguro</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="mb-0 mr-5">81 3198.4848</p>
                <p className="mb-0"> aloimperio@lojasimperio.com.br</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
