import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import api from '~/services/api';

import { IProps } from './interface/Props';

const Checkout: React.FC<IProps> = ({ match }) => {
  const { order_id, token } = match.params;

  const history = useHistory();

  useEffect(() => {
    async function getOrder() {
      try {
        if (order_id && token) {
          await api.get(`/v2/orders/${order_id}`, {
            headers: {
              authorization: `Bearer ${token}`,
            },
          });
          localStorage.setItem('imperio@order_id', order_id);
          localStorage.setItem('imperio@token', token);
          history.push(`/checkout/${order_id}`);
        }
      } catch (error) {
        console.log(error.message); // eslint-disable-line
      }
    }
    async function logoutOrder() {
      try {
        if (order_id && token) {
          await api.put(`orders/logout/${order_id}`);
          localStorage.setItem('imperio@order_id', order_id);
          localStorage.removeItem('imperio@token');
          history.push(`/checkout/${order_id}`);
        }
      } catch (error) {
        console.log(error.message); // eslint-disable-line
      }
    }
    if (order_id && token === 'not') {
      logoutOrder();
    } else {
      getOrder();
    }
  }, [order_id, token, history]);

  return (
    <div className="container page__autenticate">
      <p>Autenticando...</p>
    </div>
  );
};

export default Checkout;
