import React, { useState } from 'react';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { FaCheck, FaCheckCircle, FaCrosshairs, FaTimes } from 'react-icons/fa';
import {
  normalizeCpf,
  normalizeNumber,
  normalizePhone,
  textCaseFormat,
} from '~/utils/normalize';
import treatTimeDelivery from '~/utils/treatTimeDelivery';
import { cpfIsValid } from '~/utils/validation';
import { IOrderDeliveryOption } from '../interface/Order';

// import { Container } from './styles';

interface IProps {
  options: IOrderDeliveryOption[];
  handleSubmit: (data: any) => void;
  onHide: () => void;
}

const ModalPickupStore: React.FC<IProps> = ({
  options,
  handleSubmit,
  onHide,
}) => {
  const [showDelivery, setShowDelivery] = useState<boolean>(true);

  const [selected, setSelected] = useState({
    active: false,
    item: {} as IOrderDeliveryOption,
  });
  const [cpfWithdraw, setCpfWithdraw] = useState('');
  const [ownerWithdraw, setOwnerWithdraw] = useState<
    null | 'customer' | 'other'
  >(null);

  const getTimeDistance = (hour: string) => {
    if (!hour) return 0;

    const [hours, minutes] = hour.split(':').map(n => Number(n));

    const date = new Date().setHours(hours, minutes);

    const timeDistance = Math.floor(
      ((new Date().getTime() - date) / (1000 * 3600)) * 100,
    );

    return timeDistance;
  };

  const getStoreStatus = (
    opening_hours?: string,
  ): 'open' | 'closing' | 'closed' => {
    if (!opening_hours) {
      return 'open';
    }

    const hours = JSON.parse(opening_hours);

    if (
      !hours[String(new Date().getDay())] ||
      (hours[String(new Date().getDay())] &&
        hours[String(new Date().getDay())] === '00:00 - 00:01')
    ) {
      return 'closed';
    }

    const [open, close] = hours[String(new Date().getDay())].split(' - ');

    const timeStart = getTimeDistance(open); // minutos para abrir (se negativo, fechado)
    const timeLeft = getTimeDistance(close); // minutos para fechar (se positivo, fechado)

    if (timeStart < 0) {
      return 'closed';
    }

    if (timeLeft > -30 && timeLeft < 0) {
      return 'closing';
    }

    if (timeLeft > 0) {
      return 'closed';
    }

    return 'open';
  };

  const getTimeDelivery = (days: number, opening_hours?: string) => {
    if (!opening_hours || days > 1) {
      return (
        <>
          Disponível para Retirar a partir de{' '}
          {days >= 1 ? treatTimeDelivery(Number(days)) : '3 horas'}
        </>
      );
    }

    const status = getStoreStatus(opening_hours);

    const hours = JSON.parse(opening_hours);

    let timeToOpenToday = 0;

    if (
      hours[String(new Date().getDay())] &&
      hours[String(new Date().getDay())] !== '00:00 - 00:01'
    ) {
      timeToOpenToday = getTimeDistance(
        hours[String(new Date().getDay())]?.split(' - ')[0],
      );
    }

    const openToday = timeToOpenToday < 0;

    let availableDayToOpen = {
      hour: '',
      day: '',
    };

    let day = new Date().getDay();

    const getDayText = (dayItem: number) => {
      if (dayItem === new Date().getDay() + 1) {
        return 'Amanhã';
      }
      switch (dayItem) {
        case 1:
          return 'Segunda-Feira';
        case 2:
          return 'Terça-Feira';
        case 3:
          return 'Quarta-Feira';
        case 4:
          return 'Quinta-Feira';
        case 5:
          return 'Sexta-Feira';
        case 6:
          return 'Sábado';
        default:
          return 'Domingo';
      }
    };

    for (let c = 1; c < 6; c += 1) {
      if (day + 1 > 6) {
        day = 0;
      } else {
        day += 1;
      }
      if (hours[String(day)] && hours[String(day)] !== '00:00 - 00:01') {
        availableDayToOpen = {
          hour: hours[String(day)]?.split(' - ')[0],
          day: getDayText(day),
        };
        break;
      }
    }

    if (status === 'closed') {
      if (openToday) {
        return (
          <>
            Retirada a partir de hoje às{' '}
            {hours[String(new Date().getDay())]?.split(' - ')[0]}h
          </>
        );
      }
      return (
        <>
          Retirada a partir de {availableDayToOpen.day} às{' '}
          {availableDayToOpen.hour}h
        </>
      );
    }
    if (status === 'closing') {
      return (
        <>
          Ops! A loja fechará <br />
          em pouco tempo
          <br />
          <br />
          Provável retirada {availableDayToOpen.day} a partir das{' '}
          {availableDayToOpen.hour}h
        </>
      );
    }

    return (
      <>
        Disponível para Retirar a partir de{' '}
        {days >= 1 ? treatTimeDelivery(Number(days)) : '3 horas'}
      </>
    );
  };

  return (
    <>
      <Modal
        show={selected.active}
        onHide={() => {
          setSelected({
            active: false,
            item: {} as IOrderDeliveryOption,
          });
          setCpfWithdraw('');
          setOwnerWithdraw(null);
          setShowDelivery(true);
        }}
        centered
        className="modal modal-no-top"
      >
        <Modal.Header
          closeButton
          className="modal-stores__header align-items-center"
        >
          Retirar na Loja: {textCaseFormat(selected?.item?.store?.name || '')}
        </Modal.Header>
        <Modal.Body>
          <h6 className="text-center mt-3">Quem irá retirar o pedido?</h6>
          <div className="d-flex flex-column justify-content-center align-items-center p-3">
            <div className="optionItem__default w-100">
              <button
                type="button"
                className={ownerWithdraw === 'customer' ? 'active' : ''}
                onClick={() => setOwnerWithdraw('customer')}
              >
                .
              </button>
              <p className="mb-0">
                <strong className="mb-0">Eu Mesmo</strong>
              </p>
            </div>
            <div className="optionItem__default w-100 flex-wrap">
              <button
                type="button"
                className={ownerWithdraw === 'other' ? 'active' : ''}
                onClick={() => setOwnerWithdraw('other')}
              >
                .
              </button>
              <p className="mb-0">
                <strong className="mb-0">Outra Pessoa</strong>
              </p>
              {ownerWithdraw === 'other' && (
                <Form.Control
                  value={cpfWithdraw}
                  onChange={e => setCpfWithdraw(normalizeCpf(e.target.value))}
                  className="mt-3"
                  placeholder="CPF de quem irá retirar"
                />
              )}
              {cpfWithdraw.length === 14 && !cpfIsValid(cpfWithdraw) && (
                <Alert
                  variant="danger"
                  className="w-100 mb-0 mt-2 pl-2 pr-2 pt-1 pb-1"
                >
                  CPF Inválido
                </Alert>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-center mt-2 mb-3">
            <Button
              type="button"
              disabled={
                !ownerWithdraw || ownerWithdraw === 'other'
                  ? !cpfIsValid(cpfWithdraw)
                  : false
              }
              className="btn-imperio"
              onClick={() => {
                handleSubmit({
                  ...selected.item,
                  cpf_withdraw:
                    ownerWithdraw === 'other'
                      ? normalizeNumber(cpfWithdraw)
                      : '',
                });
                onHide();
              }}
            >
              <FaCheck /> Salvar
            </Button>
            <Button
              type="button"
              variant="outline-secondary"
              className="ml-3"
              onClick={() => {
                setSelected({
                  active: false,
                  item: {} as IOrderDeliveryOption,
                });
                setCpfWithdraw('');
                setOwnerWithdraw(null);
                setShowDelivery(true);
              }}
            >
              <FaTimes /> Cancelar
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDelivery}
        onHide={onHide}
        className="modal modal-no-top modal-stores"
        size="lg"
      >
        <Modal.Header closeButton className="modal-stores__header">
          <Modal.Title>Selecione a loja para retirada do pedido</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3 radius-4 text-center text-danger">
            <strong>Aviso</strong>
            <br />
            <p className="m-0">
              É necessário aguardar o e-mail/sms informando que o pedido pode
              ser retirado. Em caso de dúvidas, ligue para a loja selecionada.
            </p>
          </div>
          <div className="modal-stores__list">
            {options.map(e => {
              const storeStatus = getStoreStatus(e.store?.opening_hours);
              const timeDelivery = getTimeDelivery(
                e.days,
                e.store?.opening_hours,
              );
              return (
                <div className="store" key={e.id}>
                  <div
                    className={`store__availability ${
                      e.days < 1 ? 'bg-success' : ''
                    }`}
                  >
                    {timeDelivery}
                  </div>
                  <div className="store__infos">
                    <div
                      className={`store__working-status store__working-status--${storeStatus}`}
                    >
                      {storeStatus === 'open' && 'LOJA ABERTA'}
                      {storeStatus === 'closing' && 'LOJA PRESTES A FECHAR'}
                      {storeStatus === 'closed' && 'LOJA FECHADA'}
                    </div>
                    <div className="store__content">
                      <div className="store__name-distance">
                        {e.store?.name}
                      </div>
                      <div className="store__address-phone">
                        {e.store?.fullAddress} Fone:{' '}
                        {e.store?.phone
                          ? normalizePhone(
                              normalizeNumber(
                                e.store?.phone?.slice(
                                  2,
                                  e.store?.phone?.length,
                                ) || '',
                              ),
                            )
                          : ''}
                      </div>
                      <div className="store__actions">
                        <div className="store__action">
                          <FaCrosshairs />
                          <a
                            href={`https://www.google.com/maps/place/${e.store?.street},${e.store?.number},${e.store?.district},${e.store?.city},${e.store?.state} - ${e.store?.zip_code}`}
                            target="blank"
                          >
                            Obter Rota
                          </a>
                        </div>
                        <button
                          type="button"
                          className={`store__action store__select-store${
                            e.is_selected ? ' selected' : ''
                          }`}
                          onClick={() => {
                            setSelected({
                              active: true,
                              item: e,
                            });
                            setShowDelivery(false);
                          }}
                        >
                          <FaCheckCircle />
                          {e.is_selected
                            ? 'Loja Selecionada'
                            : 'Selecionar Loja'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalPickupStore;
