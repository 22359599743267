import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { IOrder } from '../interface/Order';
import { IHeaders } from '../interface/Headers';
import api from '~/services/api';

interface IProps {
  order: IOrder;
  headersTokens: IHeaders;
  getSubmitedData: () => void; // eslint-disable-line
}

const Seller: React.FC<IProps> = ({
  order,
  headersTokens,
  getSubmitedData,
}) => {
  const [dealercodeDiv, setDealercodeDiv] = useState(false);
  const [code, setCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCode(order.seller?.code || '');
  }, [order]);

  function submitSeller() {
    return getSubmitedData();
  }

  async function handleSubmitSeller() {
    setLoading(true);
    setErrorMessage('');
    try {
      await api.post(
        `orders/sellers`,
        {
          order_id: order.id,
          code,
        },
        headersTokens,
      );
      submitSeller();
    } catch (e) {
      if (e.response.data.length) {
        setErrorMessage(e.response.data[0].message);
      } else {
        setErrorMessage(e.response.data.message);
      }
    }
    setLoading(false);
  }

  async function handleRemoveSeller() {
    setLoading(true);
    setErrorMessage('');
    try {
      await api.delete(`orders/sellers/${order.id}`, headersTokens);
      submitSeller();
    } catch (e) {
      if (e.response.data.length) {
        setErrorMessage(e.response.data[0].message);
      } else {
        setErrorMessage(e.response.data.message);
      }
    }
    setLoading(false);
  }

  return (
    <div className={dealercodeDiv ? 'dealercode active' : 'dealercode'}>
      <div>
        <div>
          <p>
            Código do vendedor{' '}
            {order.seller ? (
              <small style={{ display: 'flex', fontWeight: 'bold' }}>
                {order.seller.name}
              </small>
            ) : (
              ''
            )}
          </p>
        </div>
        <button
          type="button"
          onClick={() => {
            setDealercodeDiv(!dealercodeDiv);
          }}
        >
          {dealercodeDiv ? (
            <i className="fas fa-chevron-up" />
          ) : (
            <i className="fas fa-chevron-down" />
          )}
        </button>
      </div>
      <div className="d-flex box__dealercode">
        {errorMessage !== '' && (
          <p className="alert alert-danger mb-3">{errorMessage}</p>
        )}
        <form>
          <Form.Control
            name="code"
            onChange={e => {
              setCode(String(e.target.value));
            }}
            value={code}
            disabled={loading || order.loading || !!order.seller_id}
          />
          {order.seller_id ? (
            <button
              type="button"
              onClick={() => handleRemoveSeller()}
              className="btn btn-imperio bg-danger pb-2 pl-4 pr-4 pt-2"
              style={{ border: 'none' }}
              disabled={loading || order.loading}
            >
              Remover
            </button>
          ) : (
            <button
              type="button"
              onClick={() => handleSubmitSeller()}
              className="btn btn-imperio pb-2 pl-4 pr-4 pt-2"
              disabled={loading || order.loading}
            >
              Aplicar
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default Seller;
