import React from 'react';
import { Modal } from 'react-bootstrap';
import { normalizeCnpj } from '~/utils/normalize';

// import { Container } from './styles';

export const ModalMarketplaceDetail: React.FC<{
  onHide: () => void;
  marketplace: any;
}> = ({ onHide, marketplace }) => {
  return (
    <Modal show onHide={onHide} className="modal modal-marketplace">
      <Modal.Header className="modal-marketplace__header" closeButton>
        <Modal.Title>
          <h6>Este produto é vendido e entregue por:</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-marketplace__details d-flex justify-content-between">
          <div className="modal-marketplace__infos">
            <div className="modal-marketplace__info-header d-flex justify-content-between align-items-center mb-3">
              <b>{marketplace.name}</b>
            </div>
            <div className="modal-marketplace__info d-flex flex-column mb-2">
              <b>Razão Social:</b>
              <span>{marketplace.social_reason}</span>
            </div>
            <div className="modal-marketplace__info d-flex flex-column mb-2">
              <b>CNPJ:</b>
              <span>{normalizeCnpj(marketplace.cnpj)}</span>
            </div>
            <div className="modal-marketplace__info d-flex flex-column mb-2">
              <b>Endereço:</b>
              <span>{marketplace.fullAddress}</span>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
