import React from 'react';

const Service: React.FC = () => {
  return (
    <div className="container page__confirm">
      <div className="row">
        <div className="col-12">
          <h3 className="text-center  mb-4">
            COMPLEMENTO DE BILHETE DE SEGURO DE GARANTIA ESTENDIDA ORIGINAL
          </h3>
          <p>
            Ramo de seguro 0195 &ndash; Garantia Estendida/Extens&atilde;o de
            Garantia &ndash; Bens em Geral
          </p>
          <h6>DEFINI&Ccedil;&Otilde;ES</h6>
          <p>
            <strong>Garantia Estendida:&nbsp;</strong>O seguro tem como objetivo
            fornecer ao segurado, facultativamente e mediante o pagamento de
            pr&ecirc;mio, a extens&atilde;o temporal da garantia do fornecedor
            de um bem adquirido e, quando prevista, sua
            complementa&ccedil;&atilde;o.
          </p>
          <p>
            <strong>Garantia do Fabricante</strong>: Garantia oferecida pelo
            Fabricante e prevista no Certificado de Garantia ou Manual do
            Produto.
          </p>
          <p>
            <strong>Garantia do Fornecedor</strong>: Garantia legal e, se
            houver, a garantia contratual originalmente oferecida pelo
            fornecedor, nos termos definidos pela lei.
          </p>
          <p>
            <strong>Indeniza&ccedil;&atilde;o</strong>: reparo do Bem Segurado,
            sua reposi&ccedil;&atilde;o ou pagamento, pela Seguradora, em caso
            de Sinistro Coberto pelo Seguro.
          </p>
          <p>
            <strong>Risco:&nbsp;</strong>Evento futuro e incerto, de natureza
            s&uacute;bita e imprevista, independente da vontade do Segurado,
            cuja ocorr&ecirc;ncia pode provocar preju&iacute;zos de natureza
            econ&ocirc;mica.
          </p>
          <p>
            <strong>Risco Coberto:&nbsp;</strong>Risco, previsto no seguro, que,
            em caso de concretiza&ccedil;&atilde;o, d&aacute; origem a
            Indeniza&ccedil;&atilde;o e/ou reembolso ao Segurado.
          </p>
          <p>
            <strong>Vig&ecirc;ncia do Contrato de Seguro:</strong> Se inicia no
            momento da contrata&ccedil;&atilde;o do seguro, mediante pagamento
            do Pr&ecirc;mio com o t&eacute;rmino no final da Vig&ecirc;ncia da
            Cobertura.
          </p>
          <p>
            <strong>Vig&ecirc;ncia da Cobertura:&nbsp;</strong>&Eacute; o
            momento em que o seguro contratado entra em vigor, que &eacute; no
            exato momento do t&eacute;rmino da garantia do Fabricante.
          </p>
          <h6>OBJETIVO</h6>
          <p>
            O Seguro de Garantia Estendida Original SAFRA tem como objetivo
            propiciar ao Segurado, facultativamente e mediante o pagamento de
            pr&ecirc;mio, a extens&atilde;o temporal da garantia do fornecedor
            de um bem adquirido.
          </p>
          <p>
            <strong>RISCOS COBERTOS</strong>
          </p>
          <p>
            A Cobertura de Risco oferecida neste plano de seguro &eacute; a
            Extens&atilde;o de Garantia Original, cuja vig&ecirc;ncia inicia-se
            imediatamente ap&oacute;s o t&eacute;rmino da Garantia do Fornecedor
            e que contempla as mesmas Coberturas e Exclus&otilde;es oferecidas
            pela Garantia do Fornecedor.
          </p>
          <p>
            O Seguro de&nbsp;<strong>Garantia Estendida Original Safra</strong>
            &nbsp;garante ao Segurado mediante acordo entre as partes as
            seguintes op&ccedil;&otilde;es:
          </p>
          <p>
            A Cobertura para m&atilde;o de obra e pe&ccedil;as que venham
            apresentar defeito funcional durante a Vig&ecirc;ncia da Cobertura
            do Risco
          </p>
          <p>
            No caso de impossibilidade de reparo do Bem Coberto pelo seguro, a
            Indeniza&ccedil;&atilde;o ao Segurado se dar&aacute; na forma de
            reposi&ccedil;&atilde;o por bem id&ecirc;ntico;
          </p>
          <p>
            Quando a reposi&ccedil;&atilde;o por bem id&ecirc;ntico n&atilde;o
            for poss&iacute;vel, ser&aacute; dada a op&ccedil;&atilde;o ao
            Segurado de devolu&ccedil;&atilde;o do valor consignado na Nota
            Fiscal ou de reposi&ccedil;&atilde;o por um bem de
            caracter&iacute;sticas similares, limitado ao valor da Nota Fiscal.
          </p>
          <p>
            <strong>
              A Seguradora n&atilde;o tem qualquer responsabilidade por
              eventuais defeitos ocorridos durante a garantia do
              fabricante&nbsp;
            </strong>
            <strong>e/ou fornecedor.</strong>
          </p>
          <h6>RISCOS EXCLU&Iacute;DOS</h6>
          <p>
            <strong>
              Est&atilde;o exclu&iacute;dos deste seguro quaisquer despesas,
              preju&iacute;zos, &ocirc;nus, perdas, danos ou responsabilidades
              de qualquer natureza, direta ou indiretamente causados por ou
              resultantes de, para os quais tenham contribu&iacute;do ou cujo
              pedido de Indeniza&ccedil;&atilde;o abranja:
            </strong>
          </p>
          <p>
            <strong>
              Quaisquer dos Riscos exclu&iacute;dos da Garantia do Fabricante
              e/ou do Fornecedor do Bem Segurado;
            </strong>
          </p>
          <p>
            <strong>
              Atos il&iacute;citos dolosos ou culpa grave equipar&aacute;vel ao
              Dolo, praticados pelo Segurado, pelo benefici&aacute;rio ou pelo
              representante legal de um ou de outro.
            </strong>
          </p>
          <p>
            <strong>
              Produtos, ainda que segurados, que tenham sido utilizados para
              outra finalidade ou de forma diferente das
              especifica&ccedil;&otilde;es do Fabricante.
            </strong>
          </p>
          <p>
            <strong>BENS ELEGIVEIS</strong>
          </p>
          <p>
            Ser&aacute; garantido pelo presente seguro o bem descrito no Bilhete
            e comprovado por meio de Nota Fiscal de Compra, e adquiridos dentro
            do per&iacute;odo de vig&ecirc;ncia do Bilhete de Seguro.
          </p>
          <p>
            <strong>BENS N&Atilde;O ELEG&Iacute;VEIS</strong>
          </p>
          <p>O presente seguro n&atilde;o cobre danos sofridos por:</p>
          <p>
            Produtos que sejam utilizados em estabelecimentos comerciais ou
            industriais, ou ainda, para fins comerciais ou industriais, para
            aluguel ou de qualquer maneira de uso n&atilde;o dom&eacute;stico,
            exceto Condicionadores de Ar quando utilizados em escrit&oacute;rios
            individuais e desde que a &aacute;rea refrigerada n&atilde;o exceda
            &agrave;s especifica&ccedil;&otilde;es do Fabricante;
          </p>
          <p>
            Quaisquer tipos de acess&oacute;rios n&atilde;o mencionados e
            n&atilde;o cobertos pela Garantia do Fabricante;
          </p>
          <p>
            Programas aplicativos, sistemas operacionais e softwares, sendo que
            a responsabilidade pela realiza&ccedil;&atilde;o de qualquer tipo de
            backup ou sua reinstala&ccedil;&atilde;o em decorr&ecirc;ncia do
            conserto do Produto &eacute; &uacute;nica e exclusivamente do
            comprador;
          </p>
          <p>
            Produto reserva no per&iacute;odo de conserto do Produto com
            defeito;
          </p>
          <p>
            Bens consum&iacute;veis, tais como: pilhas, baterias, fitas de
            impress&atilde;o, toner ou cartucho de tinta, papel ou filme de
            qualquer tipo, filtros e feltros de limpeza etc.;
          </p>
          <p>
            Produtos, ainda que segurados, que tenham sido utilizados para outra
            finalidade ou de forma diferente das especifica&ccedil;&otilde;es do
            Fabricante.
          </p>
          <p>
            <strong>PAGAMENTO DO PR&Ecirc;MIO</strong>
          </p>
          <p>
            O pagamento do Pr&ecirc;mio relativo &agrave;
            contrata&ccedil;&atilde;o do Seguro de Garantia Estendida Original
            Safra poder&aacute; ser feito &agrave; vista ou de forma fracionada,
            conforme acordo entre as partes, e especificado no Bilhete e
            dever&aacute; ser efetuado at&eacute; a(s) data(s) de vencimento
            expressa(s) no(s) pr&oacute;prio Bilhete.
          </p>
          <p>
            O n&atilde;o pagamento do pr&ecirc;mio implicar&aacute; no
            cancelamento autom&aacute;tico do seguro independente de qualquer
            interpela&ccedil;&atilde;o judicial ou extrajudicial.
          </p>
          <p>
            <strong>DIREITO DE ARREPENDIMENTO&nbsp;</strong>
          </p>
          <p>
            O segurado poder&aacute; desistir do seguro contratado no prazo de 7
            (sete) dias corridos a contar da emiss&atilde;o do bilhete, e
            poder&aacute; exercer seu direito de arrependimento pelo mesmo meio
            utilizado para contrata&ccedil;&atilde;o, sem preju&iacute;zo de
            utilizar os demais canais de atendimento disponibilizados pelo
            Safra.
          </p>
          <p>
            <strong>SINISTROS</strong>
          </p>
          <p>
            O Segurado deve comunicar a ocorr&ecirc;ncia de defeito do Bem
            Segurado atrav&eacute;s da Central de Atendimento assim que tomar
            conhecimento, informando o N&uacute;mero do Bilhete, a data, hora,
            local e causa do Sinistro.
          </p>
          <p>
            Os dados de contato com a Central de Atendimento encontram-se
            especificados no Bilhete.
          </p>
          <p>
            O Segurado deve informar a Senha de Atendimento recebida da Central
            de Atendimento, quando houver, e apresentar os seguintes documentos
            &agrave; Assist&ecirc;ncia T&eacute;cnica na entrega/visita para
            atendimento ao Produto e Regula&ccedil;&atilde;o do Sinistro.
          </p>
          <p>
            a) O documento fiscal de aquisi&ccedil;&atilde;o do Bem Segurado;
          </p>
          <p>b) O Bilhete do seguro; e</p>
          <p>
            c) CPF ou outro documento de identifica&ccedil;&atilde;o pessoal.
          </p>
          <p>
            No caso de pagamento de Indeniza&ccedil;&atilde;o em dinheiro,
            al&eacute;m dos documentos listados no item anterior, poder&aacute;
            ser solicitada c&oacute;pia de comprovante atual de endere&ccedil;o
            e de telefone.
          </p>
          <p>
            A Seguradora ter&aacute; o prazo de at&eacute; 30 (trinta) dias para
            o cumprimento das obriga&ccedil;&otilde;es de extens&atilde;o da
            garantia original do Bem Segurado, sendo que o in&iacute;cio da
            contagem desse prazo estabelecido ocorrer&aacute;:
          </p>
          <p>
            a) Na data da entrega do bem na assist&ecirc;ncia t&eacute;cnica ou
            ponto de coleta, juntamente com os documentos b&aacute;sicos
            previstos no item 15.6 desta cl&aacute;usula;
          </p>
          <p>
            b) Na data da comunica&ccedil;&atilde;o do Sinistro pelo Segurado,
            quando for necess&aacute;ria a retirada do Bem Segurado ou o
            atendimento em domic&iacute;lio, por representante ou empresa
            indicada pela Seguradora;
          </p>
          <p>
            A responsabilidade pela entrega ou retirada do Bem Segurado
            seguir&aacute; a orienta&ccedil;&atilde;o disposta na garantia do
            fornecedor, ou outra, mais ben&eacute;fica ao Segurado, mediante
            acordo entre as partes.
          </p>
          <p>
            Eventuais custos de transporte do Bem Segurado sinistrado para
            reparo ou reposi&ccedil;&atilde;o ser&atilde;o de responsabilidade
            da Seguradora, observada a orienta&ccedil;&atilde;o disposta na
            garantia do fornecedor do Bem Segurado.
          </p>
          <p>
            <strong>Consulte os N&uacute;meros de 0800:</strong>
          </p>
          <p>
            <strong>Sinistro:</strong>
          </p>
          <p>
            <strong>0800 770 4202 &ndash; 24 horas 7 dias na semana</strong>
          </p>
          <p>
            <strong>
              SAC &ndash; Servi&ccedil;o de Atendimento ao Consumidor
            </strong>
          </p>
          <p>
            <strong>0800 772 5755 - 24 horas 7 dias na semana</strong>
          </p>
          <p>
            <strong>Deficientes Auditivos ou de Fala</strong>
          </p>
          <p>
            <strong>0800 772 4136 - 24 horas 7 dias na semana</strong>
          </p>
          <p>
            <strong>Ouvidoria:</strong>
          </p>
          <p>
            <strong>0800 770 1236 -&nbsp;</strong>
            <strong>de segunda &agrave; sexta das 08h00 &agrave;s 18h00</strong>
          </p>
          <p>
            <strong>Deficientes Auditivos ou de Fala</strong>
          </p>
          <p>
            <strong>0800 727 7555 -&nbsp;</strong>
            <strong>de segunda &agrave; sexta das 08h00 &agrave;s 18h00</strong>
          </p>
          <p>
            <br />
          </p>
          <p>
            <strong>DISQUE SUSEP: 0800 021 8484</strong>
          </p>
          <p>
            <strong>
              O registro do produto &eacute; autom&aacute;tico e n&atilde;o
              representa aprova&ccedil;&atilde;o ou recomenda&ccedil;&atilde;o
              por parte da Susep.
            </strong>
          </p>
          <p>
            <strong>
              Este seguro &eacute; por prazo determinado, tendo a Seguradora, a
              faculdade de n&atilde;o renovar.
            </strong>
          </p>
          <p>
            <strong>
              As condi&ccedil;&otilde;es contratuais deste produto
              protocolizadas pela seguradora junto a SUSEP poder&atilde;o ser
              consultadas no endere&ccedil;o eletr&ocirc;nico&nbsp;
            </strong>
            <u>
              <a href="http://www.susep.gov.br/">
                <strong>www.susep.gov.br</strong>
              </a>
            </u>
            <strong>
              , de acordo com o n&uacute;mero de processo constante no bilhete.
              Incidem as al&iacute;quotas de 0,65% de PIS/Pasep e de 4% de
              CONFINS sobre os pr&ecirc;mio de seguros, deduzidos do
              estabelecido em legisla&ccedil;&atilde;o especificada.
            </strong>
          </p>
          <p>
            <br />
          </p>

          <div className="d-flex justify-content-center">
            <img
              src="/assets/img/signature-safra.png"
              alt="Assinatura"
              style={{ width: '100%', maxWidth: '180px' }}
            />
          </div>
          <p style={{ textAlign: 'center', lineHeight: '115%' }}>
            <strong>________________________________</strong>
          </p>
          <p style={{ lineHeight: '115%', textAlign: 'center' }}>
            <strong>Safra Seguradora S/A</strong>
          </p>
          <div className="d-flex justify-content-center">
            <img
              src="/assets/img/safra-logo.png"
              alt="Safra"
              style={{ width: '100%', maxWidth: '80px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
