import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  @media screen and (min-width: 200px) {
    padding: 10px;
  }
`;

interface IContent {
  size?: 'big' | 'xl';
}

export const Content = styled.div`
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 40px;
  width: ${(props: IContent) => {
    if (props.size === 'xl') {
      return 900;
    }
    if (props.size === 'big') {
      return 600;
    }
    return 400;
  }}px;
  position: relative;

  .close {
    position: absolute;
    top: 10px;
    right: 18px;
    font-size: 20px;
    color: gray;
  }
  h1 {
    font-size: 26px;
    font-weight: 500;
    text-align: center;
    margin: 0 0 10px;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    > span {
      color: #2d3047;
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
      margin-top: 15px;
    }
    > input {
      height: 40px;
      padding: 10px;
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      background: rgba(0, 0, 0, 0.1);
      color: #222;
      margin-top: 8px;
      transition: border 0.15s ease;
      font-size: 16px;
      @media (max-width: 600px) {
        width: 85%;
      }
      &:focus {
        border-color: #016cb2;
      }
    }
    > button {
      margin-top: 20px;
    }
  }
  @media screen and (max-width: 600px) {
    height: 60vh;
    overflow-y: scroll;
  }
`;
