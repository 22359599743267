import api from '~/services/api';
import { normalizeCpf, normalizeNumber } from '~/utils/normalize';
import React, { useEffect, useRef, useState } from 'react';
import { Form, Modal, Spinner } from 'react-bootstrap';
import { IoMdWarning } from 'react-icons/io';

import Address from './components/Address';
import Authentication from './components/Authentication';
import Coupon from './components/Coupon';
import Customer from './components/Customer';
import Payment from './components/Payment';
import Products from './components/Products';
import Resume from './components/Resume';
import Seller from './components/Seller';
import { IHeaders } from './interface/Headers';
import { IOrder } from './interface/Order';
import { IProps } from './interface/Props';
import { ResumeContainer } from './styles';
import TagManager from 'react-gtm-module';

interface IError {
  type: string;
  status: number;
  alert: string;
  message: string;
}

const Checkout: React.FC<IProps> = ({ match }) => {
  const [promocionalModal, setPromocionalModal] = useState(false);
  const [order, setOrder] = useState<IOrder>({
    id: 0,
    seller_id: 0,
    employee_id: null,
    order_status_id: 0,
    code_coupon: '',
    free_shipping: false,
    service: 0,
    subtotal: 0,
    discount: 0,
    discount_form_payment: 0,
    frete: 0,
    total: 0,
    orderDeliveries: [],
    loading: true,
    mask: true,
  });
  const [error, setError] = useState<IError>({
    type: '',
    status: 0,
    alert: '',
    message: '',
  });
  const [modalAutentication, setModalAutentication] = useState<boolean>(false);
  const [updateCpf, setUpdateCpf] = useState({
    open: false,
    value: '',
    loading: false,
  });

  const [total, setTotal] = useState(0);

  const hasLiveloPoints =
    order?.orderProducts &&
    order?.orderProducts?.findIndex(o => o.livelo_points > 0) > -1;

  const { order_id } = match.params;

  const headersTokens = (): IHeaders => {
    const token = localStorage.getItem('imperio@token');
    const tokenBuyer = localStorage.getItem('imperio@tokenBuyer');
    if (token) {
      return {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
    }
    if (tokenBuyer) {
      return {
        headers: {
          tokenBuyer,
        },
      };
    }
    return {
      headers: {},
    };
  };

  async function verifyCpf() {
    if (
      localStorage.getItem('imperio@token') ||
      localStorage.getItem('imperio@tokenBuyer')
    ) {
      try {
        const headers: IHeaders = headersTokens();
        await api.get(`/customer/me/verify-cpf`, headers);
      } catch (err) {
        if ((err as any)?.response?.status === 400) {
          if (!localStorage.getItem('imperio@token')) {
            setModalAutentication(true);
            alert('Necessário autenticar para prosseguir');
          } else {
            setUpdateCpf({
              ...updateCpf,
              open: true,
            });
          }
        }
      }
    }
  }

  async function handleUpdateCpf() {
    setUpdateCpf({
      ...updateCpf,
      loading: true,
    });
    try {
      const headers: IHeaders = headersTokens();
      await api.put(
        `/customer/me/cpf`,
        {
          cpf: normalizeNumber(updateCpf.value),
        },
        headers,
      );
      setUpdateCpf({
        ...updateCpf,
        loading: false,
        open: false,
      });
    } catch (err) {
      alert(err?.response?.data?.message);
      setUpdateCpf({
        ...updateCpf,
        loading: false,
      });
    }
  }

  async function getOrder() {
    try {
      setOrder({ ...order, loading: true });

      const headers: IHeaders = headersTokens();

      const { data: dataOrder } = await api.get(
        `/v2/orders/${localStorage.getItem('imperio@order_id')}`,
        headers,
      );
      setOrder({ ...dataOrder, loading: false });
      setTotal(dataOrder.total);
    } catch (e) {
      if (e?.response?.status === 401) {
        setModalAutentication(true);
      }
    }
  }

  const itemRef = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (itemRef && itemRef.current) {
        const c: any = itemRef.current; // eslint-disable-line
        c.style.transform = `translateY(${
          window.pageYOffset > 110 ? window.pageYOffset - 110 : 0
        }px)`;
      }
    });
  }, []);

  useEffect(() => {
    async function getQuote() {
      try {
        if (order_id) {
          const headers: IHeaders = headersTokens();
          const { data: dataOrder } = await api.get(
            `/v2/orders/${order_id}`,
            headers,
          );
          localStorage.setItem('imperio@order_id', order_id);

          if (dataOrder.code_coupon !== '' && dataOrder.code_coupon !== null) {
            try {
              await api.post(
                `orders/coupons`,
                {
                  order_id,
                  code: dataOrder.code_coupon,
                },
                headers,
              );
            } catch (e) {
              if (e?.response?.status === 401) {
                setModalAutentication(true);
              }
            } // eslint-disable-line
          }

          setOrder({ ...dataOrder, loading: false });
          setTotal(dataOrder.total);
          await verifyCpf();
        }
      } catch (e) {
        if (e?.response?.status === 401) {
          setModalAutentication(true);
        }
      }
    }

    if (order_id !== localStorage.getItem('imperio@order_id')) {
      localStorage.removeItem('imperio@order_id');
      localStorage.removeItem('imperio@tokenBuyer');
      localStorage.removeItem('imperio@token');
    }

    getQuote();
  }, [order_id]); // eslint-disable-line

  async function orderPromotion(choice: boolean) {
    setPromocionalModal(false);
    setOrder({
      ...order,
      loading: true,
    });
    try {
      await api.put(`/orders/promotion/${order.id}`, {
        choice,
      });
    } catch (e) {
      if (e.response.data.length) {
        setError({
          type: 'customer',
          status: e.response.status,
          alert: 'danger',
          message: e.response.data[0].message,
        });
      } else {
        setError({
          type: 'customer',
          status: e.response.status,
          alert: 'danger',
          message: e.response.data.message,
        });
      }
      if (e?.response?.status === 401) {
        setModalAutentication(true);
      }
      setPromocionalModal(false);
    }
    setOrder({
      ...order,
      loading: false,
    });
    setTotal(order.total);
  }

  async function submitUpdateOrder(email: string) {
    try {
      const { data: dataToken } = await api.post(`/auth/buyer/email`, {
        email,
      });
      localStorage.setItem('imperio@tokenBuyer', dataToken.token);

      await api.put(
        `/orders/${localStorage.getItem('imperio@order_id')}`,
        {},
        {
          headers: {
            tokenBuyer: dataToken.token,
          },
        },
      );
      await getOrder();
      await verifyCpf();
      TagManager.dataLayer({
        dataLayerName: 'PageDataLayer',
        dataLayer: {
          event: 'autenticar',
        },
      });
    } catch (e) {
      if (e.response.data.length) {
        setError({
          type: 'customer',
          status: e.response.status,
          alert: 'danger',
          message: e.response.data[0].message,
        });
      } else {
        setError({
          type: 'customer',
          status: e.response.status,
          alert: 'danger',
          message: e.response.data.message,
        });
      }
      if (e?.response?.status === 401) {
        setModalAutentication(true);
      }
    }
  }

  function handleModalAutentication(modal: boolean) {
    setModalAutentication(modal);
  }

  async function submitAuthenticationCustomer(token: string) {
    localStorage.setItem('imperio@token', token);
    setModalAutentication(false);
    await getOrder();
    await verifyCpf();
  }

  async function submitLogoutCustomer() {
    try {
      setError({
        type: '',
        status: 0,
        alert: '',
        message: '',
      });

      const headers: IHeaders = headersTokens();

      await api.put(
        `/orders/logout/${localStorage.getItem('imperio@order_id')}`,
        {},
        headers,
      );

      localStorage.removeItem('imperio@tokenBuyer');
      localStorage.removeItem('imperio@token');

      await getOrder();
    } catch (e) {
      if (e?.response?.status === 401) {
        setModalAutentication(true);
      }
    }
  }

  async function submitedAddService(service_id: number) {
    try {
      setOrder({ ...order, loading: true });

      const headers: IHeaders = headersTokens();

      const findService_id = order.orderServices?.filter(
        s => s.service_id === service_id,
      );

      if (findService_id?.length === 0) {
        await api.post(
          `orders/services`,
          {
            service_id,
            order_id: localStorage.getItem('imperio@order_id'),
          },
          headers,
        );
      } else {
        // await api.delete(`orders/services/${order.id}`, headers);
      }
      await getOrder();
    } catch (e) {
      if (e?.response?.status === 401) {
        setModalAutentication(true);
      }
    }
  }

  async function submitedRemoveService(product_sku: string) {
    try {
      setOrder({ ...order, loading: true });

      const headers: IHeaders = headersTokens();

      await api.delete(`orders/services/${order.id}/${product_sku}`, headers);

      await getOrder();
    } catch (e) {
      if (e?.response?.status === 401) {
        setModalAutentication(true);
      }
    }
  }

  async function submitAddressDelivery(address_id: number) {
    try {
      setOrder({ ...order, loading: true });

      const headers: IHeaders = headersTokens();

      await api.post(
        `v2/orders/address/delivery`,
        {
          customer_address_id: address_id,
          order_id: localStorage.getItem('imperio@order_id'),
        },
        headers,
      );
      await getOrder();
    } catch (e) {
      if (e?.response?.status === 401) {
        setModalAutentication(true);
      }
    }
  }

  async function submitDeliveryOrder(option_id: number, cpf_withdraw?: string) {
    try {
      setOrder({ ...order, loading: true });

      const headers: IHeaders = headersTokens();

      await api.post(
        `orders/deliveries/multiple`,
        {
          order_id: localStorage.getItem('imperio@order_id'),
          option_id,
          cpf_withdraw: cpf_withdraw || undefined,
        },
        headers,
      );
      await getOrder();
    } catch (e) {
      if (e?.response?.status === 401) {
        setModalAutentication(true);
      }
    }
  }

  return (
    <div className="container page__checkout">
      <Modal className="modal-no-top" show={promocionalModal} centered>
        <Modal.Body>
          <div className="p-3">
            <div
              className="d-flex justify-content-center"
              style={{ marginBottom: '1rem' }}
            >
              <img src="/assets/img/logo.png" alt="Lojas Império" />
            </div>
            <h5 className="text-center">
              Você quer participar da promoção de aniversário das Lojas Império?
            </h5>
            <small className="d-block text-center">
              Quer saber mais?{' '}
              <a
                href="https://aniversario.lojasimperio.com.br:2021/"
                target="blank"
              >
                Clique Aqui!
              </a>{' '}
              Participe!
            </small>
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: '1rem' }}
            >
              <button
                type="button"
                className="btn btn-orange"
                onClick={() => orderPromotion(true)}
              >
                Sim
              </button>
              <button
                type="button"
                className="btn btn-back"
                style={{ marginLeft: '1rem' }}
                onClick={() => orderPromotion(false)}
              >
                Não
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {updateCpf.open && (
        <Modal
          centered
          show
          className="modal-no-top"
          onHide={() => {
            //
          }}
        >
          <Modal.Body>
            <h4 className="text-center">
              <IoMdWarning className="text-warning mb-3" size="4rem" />
              <br />
              Atualização Necessária
            </h4>
            <p className="text-center">
              O CPF vinculado ao seu cadastro está inválido, digite um CPF
              válido para continuar.
            </p>
            <Form.Control
              placeholder="CPF"
              value={updateCpf.value}
              disabled={updateCpf.loading}
              onChange={e =>
                setUpdateCpf({
                  ...updateCpf,
                  value: normalizeCpf(e.target.value),
                })
              }
            />
            <div className="d-flex mt-2 justify-content-center">
              <button
                onClick={handleUpdateCpf}
                disabled={updateCpf.loading}
                type="button"
                className="btn btn-imperio"
              >
                {updateCpf.loading && <Spinner size="sm" animation="border" />}{' '}
                Salvar
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <div className="row" id="container-block">
        <div className="col-12 col-md-8">
          {modalAutentication && (
            <Authentication
              cancelModalAutentication={() => {
                setModalAutentication(false);
                verifyCpf();
              }}
              getSubmitedDataAuthentication={(token: string) =>
                submitAuthenticationCustomer(token)
              }
            />
          )}
          <Customer
            order={order}
            error={error}
            headersTokens={headersTokens()}
            activeModalAutentication={(modal: boolean) =>
              handleModalAutentication(modal)
            }
            getSubmitedData={(email: string) => submitUpdateOrder(email)}
            getSubmitedDataLogout={() => submitLogoutCustomer()}
            getSubmitedDataRegister={() => getOrder()}
          />
          {order.loading && (
            <div>
              <h3>SELECIONE SEU ENDEREÇO</h3>
              <p className="textLoading" />
              <br />
              <h3>ITENS DO PEDIDO</h3>
              <p className="textLoading" style={{ height: 75 }} />
              <br />
            </div>
          )}
          {order.customer && !order.loading && (
            <>
              <h3>SELECIONE SEU ENDEREÇO</h3>

              <div className="mb-4">
                <Address
                  order={order}
                  headersTokens={headersTokens()}
                  getSubmitedData={(address_id: number) =>
                    submitAddressDelivery(address_id)
                  }
                  activeModalAutentication={(modal: boolean) =>
                    handleModalAutentication(modal)
                  }
                />
              </div>
              <div className="align-items-center d-flex flex-wrap justify-content-between mt-3 mb-3">
                <h3>ITENS DO PEDIDO</h3>
                <p className="textLoading" />
                <br />
              </div>
              <div className="painel-product mb-3">
                <Products
                  order={order}
                  getSubmitedAddService={(service_id: number) =>
                    submitedAddService(service_id)
                  }
                  getSubmitedRemoveService={(product_sku: string) =>
                    submitedRemoveService(product_sku)
                  }
                  getSubmitedDelivery={(option_id, cpf_withdraw) => {
                    submitDeliveryOrder(option_id, cpf_withdraw);
                  }}
                />
              </div>
              {order.formPayments &&
                !order?.orderDeliveries?.find(
                  d => !d.deliveryOptions.find(o => o.is_selected),
                ) &&
                !order.loading && (
                  <>
                    <h3 className="mt-4">PAGAMENTO</h3>
                    <div className="mb-4">
                      <Payment
                        order={order}
                        headersTokens={headersTokens()}
                        setTotal={setTotal}
                      />
                    </div>
                  </>
                )}
              {(!order?.orderDeliveries?.find(d =>
                d.deliveryOptions.find(o => o.is_selected),
              ) ||
                !order?.orderDeliveryAddress) &&
                !order.loading && (
                  <>
                    <h3 className="mt-4">PAGAMENTO</h3>
                    <div className="mb-4 painel">
                      <div className="price text-center pt-5 pb-5">
                        {!order?.orderDeliveryAddress && (
                          <h4 className="m-0">Escolha um endereço</h4>
                        )}
                        {order?.orderDeliveryAddress &&
                          !order?.orderDeliveries?.find(d =>
                            d.deliveryOptions.find(o => o.is_selected),
                          ) && (
                            <h4 className="m-0">
                              Escolha uma forma de entrega
                            </h4>
                          )}
                      </div>
                    </div>
                  </>
                )}
            </>
          )}
        </div>
        <div className="col-12 col-md-4">
          <ResumeContainer
            ref={itemRef}
            className="resumeOrder"
            id="resumeOrder"
          >
            <h3>Resumo do pedido</h3>
            <div className="painel mb-3">
              <Resume order={order} total={total} />
            </div>
            {hasLiveloPoints ? (
              <div className="painel mb-3">
                <div className="d-flex align-items-center p-2">
                  <img
                    src="https://www.lojasimperio.com.br/images/livelo.png"
                    alt="Livelo"
                    style={{ maxWidth: '60px' }}
                    className="w-100"
                  />
                  <div className="ml-2">
                    <strong>
                      Finalize a compra e aguarde a aprovação do pagamento para
                      garantir seus pontos
                    </strong>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="painel mb-3">
              <Coupon
                order={order}
                headersTokens={headersTokens()}
                getSubmitedData={() => getOrder()}
              />
            </div>
            <div className="painel mb-3">
              <Seller
                order={order}
                headersTokens={headersTokens()}
                getSubmitedData={() => getOrder()}
              />
            </div>
          </ResumeContainer>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
