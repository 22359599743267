import React, { useMemo } from 'react';

import { IOrder } from '../interface/Order';
import { formatCurrency } from '~/utils/formatting';

interface IProps {
  order: IOrder;
  total: number;
}

const Resume: React.FC<IProps> = ({ order, total }) => {
  const totalDiscount = useMemo(() => {
    let subtotal: number | undefined;
    // if (order.orderDeliveries) {

    order?.orderDeliveries?.forEach(orderDelivery => {
      if (!subtotal) subtotal = 0;
      subtotal += orderDelivery.orderProducts?.reduce((acc, cur) => {
        if (
          (cur.product.price_discount || cur.product.price_in_full) &&
          !order.code_coupon
        ) {
          return (
            acc +
            cur.qtd * (cur.product.price_in_full || cur.product.price_discount)
          );
        }
        return acc + cur.qtd * cur.product.price_end;
      }, 0);
    });

    return (
      order.service -
      order.discount +
      order.frete +
      (subtotal || order.subtotal)
    );
  }, [order]);

  return (
    <div className="price">
      <div>
        <span>
          Subtotal{' '}
          <small style={{ fontSize: '65%' }}>
            ({!order.loading && order.orderProducts?.length}{' '}
            {(order?.orderProducts?.length || 0) > 1 ? 'Produtos' : 'Produto'})
          </small>
        </span>
        <p className={order.loading ? 'textLoading' : ''}>
          {!order.loading && formatCurrency(String(order.subtotal))}
        </p>
      </div>
      <div>
        <span>Serviços</span>
        <p className={order.loading ? 'textLoading' : ''}>
          {!order.loading && formatCurrency(String(order.service))}
        </p>
      </div>
      <div>
        <span>Frete</span>
        <p className={order.loading ? 'textLoading' : ''}>
          {!order.loading && formatCurrency(String(order.frete))}
        </p>
      </div>
      <div>
        <span>Desconto</span>
        <p className={order.loading ? 'textLoading' : ''}>
          {!order.loading && formatCurrency(String(order.discount))}
        </p>
      </div>
      <div>
        <span>Total</span>
        <p className={order.loading ? 'textLoading' : 'text-end d-block w-100'}>
          {!order.loading && formatCurrency(String(total))}
          {!order.loading && total > totalDiscount ? (
            <small className="d-block text-primary">
              ou <strong>{formatCurrency(String(totalDiscount))}</strong>
              <br />à vista no Pix
            </small>
          ) : null}
        </p>
      </div>
    </div>
  );
};

export default Resume;
