import React, { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

import Input from '~/components/Input';
import Modal from '~/components/Modal';
import { IOrder } from '../interface/Order';
import { IHeaders } from '../interface/Headers';
import { formatCpf, formatPhone } from '~/utils/formatting';
import { normalizeCpf, normalizeDate, normalizePhone } from '~/utils/normalize';
import api from '~/services/api';
import { cpfIsValid } from '~/utils/validation';

import TagManager from 'react-gtm-module';
import ReCAPTCHA from 'react-google-recaptcha';

interface IProps {
  order: IOrder;
  headersTokens: IHeaders;
  error: {
    type: string;
    alert: string;
    status: number;
    message: string;
  };
  activeModalAutentication: (modal: boolean) => void;
  getSubmitedData: (data: any) => void; // eslint-disable-line
  getSubmitedDataRegister: () => void; // eslint-disable-line
  getSubmitedDataLogout: () => void; // eslint-disable-line
}

const Customer: React.FC<IProps> = ({
  order,
  error,
  headersTokens,
  activeModalAutentication,
  getSubmitedData,
  getSubmitedDataRegister,
  getSubmitedDataLogout,
}) => {
  const [name, setName] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [cpf, setCpf] = useState<string>('');
  const [birthdate, setBirthdate] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [newsletter, setNewsletter] = useState(false);
  const [authPhone, setAuthPhone] = useState<boolean>(false);
  const [infoMessage, setInfoMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorMessageCode, setErrorMessageCode] = useState<string>('');

  const reRef = useRef<any>(null);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    return getSubmitedData(email);
  }

  function submitRegister() {
    return getSubmitedDataRegister();
  }

  async function handleSubmitRegister() {
    if (!cpfIsValid(cpf)) {
      setErrorMessage('CPF Inválido');
      return;
    }

    const gResponse = await reRef?.current?.executeAsync();
    reRef?.current?.reset();

    if (!gResponse) {
      setErrorMessage('Erro, atualize a tela e tente novamente');
      window.location.href = '#error__message';
      return;
    }

    if (!birthdate) {
      setErrorMessage('Informe a data de nascimento');
      window.location.href = '#error__message';
      return;
    }

    const [day, month, year] = String(birthdate).split('/');

    try {
      setErrorMessage('');
      setErrorMessageCode('');
      setInfoMessage('');
      setSuccessMessage('');
      const { data: dataCustomer } = await api.post(
        `customers`,
        {
          name,
          lastname,
          email,
          cpf: cpf.replace(/[^\d]/g, ''),
          phone: phone.replace(/[^\d]/g, ''),
          birthdate: `${year}-${month}-${day}T03:00:00`,
          newsletter,
          gResponse,
        },
        headersTokens,
      );

      try {
        TagManager.dataLayer({
          dataLayerName: 'PageDataLayer',
          dataLayer: {
            event: 'sign_up',
            method: `${name} ${lastname} - ${email}`,
          },
        });
      } catch (err) {
        //
      }

      await api.put(
        `/orders/${localStorage.getItem('imperio@order_id')}`,
        {},
        {
          headers: {
            authorization: `Bearer ${dataCustomer.token}`,
          },
        },
      );
      localStorage.setItem('imperio@token', dataCustomer.token);
      submitRegister();
      TagManager.dataLayer({
        dataLayerName: 'PageDataLayer',
        dataLayer: {
          event: 'autenticar',
        },
      });
    } catch (erro) {
      if (erro.response.data.length) {
        if (
          erro.response.data[0].field === 'phone' &&
          erro.response.data[0].validation === 'unique'
        ) {
          setInfoMessage(erro.response.data[0].message);
        } else {
          setErrorMessage(erro.response.data[0].message);
        }
      } else {
        setErrorMessage(erro.response.data.message);
      }
    }
  }

  async function handleSubmitAuthPhone() {
    try {
      setErrorMessage('');
      setErrorMessageCode('');
      setInfoMessage('');
      setSuccessMessage('');
      await api.post(`auth/customer/phone?complexity=6`, {
        phone: phone.replace(/[^\d]/g, ''),
      });
      setSuccessMessage('Código enviado com sucesso');
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
  }

  async function handleSubmitAuthCodePhone() {
    try {
      setErrorMessage('');
      setErrorMessageCode('');
      setInfoMessage('');
      setSuccessMessage('');
      const { data } = await api.post(`authentication/customer/phone`, {
        phone: phone.replace(/[^\d]/g, ''),
        code,
      });
      localStorage.setItem('imperio@token', data.token);
      setAuthPhone(false);
      submitRegister();
    } catch (err) {
      setErrorMessageCode(err.response.data.message);
    }
  }

  function handleSubmitLogout() {
    setErrorMessage('');
    setErrorMessageCode('');
    setInfoMessage('');
    setSuccessMessage('');
    return getSubmitedDataLogout();
  }

  function handleModalAutentication(modal: boolean) {
    setErrorMessage('');
    setErrorMessageCode('');
    setInfoMessage('');
    setSuccessMessage('');
    return activeModalAutentication(modal);
  }

  return (
    <>
      <div className="align-items-center d-flex justify-content-between mt-3 mb-3">
        <h3>INFORMAÇÕES DE FATURAMENTO</h3>
        <div className="d-flex align-items-center">
          {order.customer && !order.loading && order.mask && (
            <button
              className="btn btn-imperio btn-carrinho mr-2"
              type="button"
              onClick={() => handleModalAutentication(true)}
            >
              Visualizar dados completos
            </button>
          )}
          {order.employee_id === null && order.id && (
            <a
              href={`https://lojasimperio.com.br/c/carrinho/${order.id}`}
              className="btn btn-imperio btn-carrinho"
            >
              Voltar para o carrinho
            </a>
          )}
        </div>
      </div>
      {authPhone && (
        <Modal size="big">
          <button
            type="button"
            className="close"
            onClick={() => setAuthPhone(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
          <div>
            <p className="text-center">
              Enviamos um código via SMS para o seu celular. Digite o Código nos
              campos abaixo para ter acesso a sua conta.
            </p>
            {errorMessageCode !== '' && (
              <p className="alert alert-danger">{errorMessageCode}</p>
            )}
            {successMessage !== '' && (
              <p className="alert alert-success">{successMessage}</p>
            )}
            <Input
              label="Código"
              name="code"
              required
              onChange={e => setCode(String(e.value))}
            />
            <div className="align-items-center d-flex flex-column">
              <button
                className="btn btn-imperio mt-4"
                type="button"
                onClick={() => handleSubmitAuthCodePhone()}
              >
                Enviar código
              </button>
              <button
                className="btn btn-link mt-3"
                type="button"
                onClick={() => handleSubmitAuthPhone()}
              >
                Não recebeu o SMS? Tente novamente
              </button>
            </div>
          </div>
        </Modal>
      )}
      <div className="mb-4">
        {order.customer && !order.loading ? (
          <>
            <p>
              O pedido será feito em nome de{' '}
              <strong className={order.loading ? 'textLoading' : ''}>
                {!order.loading &&
                  `${order.customer.name} ${order.customer.lastname}`}
              </strong>
              , utilizando o CPF:{' '}
              <strong className={order.loading ? 'textLoading' : ''}>
                {!order.loading && order.mask
                  ? order.customer.cpf
                  : formatCpf(order.customer.cpf)}
              </strong>
              . <br /> Para entramos em contato usaremos o e-mail{' '}
              <strong className={order.loading ? 'textLoading' : ''}>
                {!order.loading && order.customer.email}
              </strong>{' '}
              e o Celular{' '}
              <strong className={order.loading ? 'textLoading' : ''}>
                {!order.loading && order.mask
                  ? order.customer.phone
                  : formatPhone(order.customer.phone)}
              </strong>
              .
            </p>
            <button
              type="button"
              className="btn-logout"
              onClick={() => handleSubmitLogout()}
            >
              Não é você? Sair
            </button>
          </>
        ) : (
          <div>
            {order.loading ? (
              <div>
                <p className="textLoading" />
              </div>
            ) : (
              <>
                <p>Para finalizar seu pedido informe seu e-mail</p>
                {error.type === 'customer' && error.status === 400 && (
                  <p className="alert alert-danger">{error.message}</p>
                )}
                {error.type === 'customer' && error.status === 404 ? (
                  <form className="col-8 d-flex flex-column">
                    {errorMessage !== '' && (
                      <p className="alert alert-danger">{errorMessage}</p>
                    )}
                    {infoMessage !== '' && (
                      <p className="alert alert-warning">
                        {infoMessage}{' '}
                        <button
                          type="button"
                          className="btn-link"
                          onClick={() => {
                            handleSubmitAuthPhone();
                            setAuthPhone(true);
                          }}
                        >
                          clique aqui
                        </button>{' '}
                        para autenticar a sua conta por SMS
                      </p>
                    )}
                    <div className="row">
                      <div className="col-6">
                        <Input
                          label="Nome"
                          name="name"
                          required
                          onChange={e => setName(String(e.value))}
                        />
                      </div>
                      <div className="col-6">
                        <Input
                          label="Sobrenome"
                          name="lastname"
                          required
                          onChange={e => setLastname(String(e.value))}
                        />
                      </div>
                      <div className="col-12">
                        <Input
                          label="E-mail"
                          name="email"
                          required
                          initialValue={email}
                          disabled
                          onChange={e => setEmail(String(e.value))}
                        />
                      </div>
                      <div className="col-12">
                        <Input
                          label="Data de nascimento"
                          name="birthdate"
                          required
                          normalize={normalizeDate}
                          onChange={e => setBirthdate(String(e.value))}
                        />
                      </div>
                      <div className="col-6">
                        <Input
                          label="CPF"
                          name="cpf"
                          required
                          normalize={normalizeCpf}
                          onChange={e => setCpf(String(e.value))}
                        />
                      </div>
                      <div className="col-6">
                        <Input
                          label="Celular"
                          name="phone"
                          required
                          normalize={normalizePhone}
                          onChange={e => setPhone(String(e.value))}
                        />
                      </div>
                      <div className="col-12 mt-3">
                        <Form.Group>
                          <Form.Check
                            label="Desejo receber ofertas e promoções via e-mail"
                            checked={newsletter}
                            onChange={() => setNewsletter(!newsletter)}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <button
                      className="btn btn-imperio mt-4"
                      type="button"
                      onClick={() => handleSubmitRegister()}
                    >
                      Concluir meu cadastro
                    </button>
                    <ReCAPTCHA
                      sitekey="6Lf8NtUaAAAAAEJyuqWiJAo9K4-y_2KqBfP6A_VM"
                      size="invisible"
                      ref={reRef}
                    />
                  </form>
                ) : (
                  <>
                    <form
                      onSubmit={handleSubmit}
                      className="col-8 d-flex box__discount_coupon flex-wrap"
                    >
                      <Form.Control
                        name="email"
                        onChange={e => setEmail(e.target.value)}
                        className="mt-2"
                        style={{ width: '100%', maxWidth: '200px' }}
                      />
                      <button
                        className="btn btn-imperio mt-2 ml-3 pb-2 pl-4 pr-4 pt-2"
                        type="submit"
                      >
                        Continuar
                      </button>
                    </form>
                    <div className="span_info mt-4 p-3">
                      <p> Usamos seu e-mail para:</p>
                      <ul>
                        <li> Identificar seu perfil</li>
                        <li> Acelerar o preenchimento de suas informações</li>
                        <li> Notificar sobre o andamento do seu pedido</li>
                      </ul>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Customer;
