import React from 'react';
import { Modal } from 'react-bootstrap';

interface IProps {
  open: boolean;
  setOpen: () => void;
}

export const ModalWarrantyExtended: React.FC<IProps> = ({ open, setOpen }) => {
  return (
    <Modal centered show={open} onHide={setOpen} size="lg">
      <Modal.Header closeButton className="align-items-start">
        <div>
          <h5 className="text-center">O Seguro de Garantia Estendida</h5>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>
            Em parceria com a Safra Seguros Gerais, as lojas Império oferecem
            aos seus clientes consumidores o Seguro de Garantia Estendida
            Original, de 12 até 24 meses, a depender do plano escolhido, contra
            defeitos funcionais, com seu início de garantia imediatamente após o
            término da garantia do fabricante.
          </p>
          <h5 className="text-center">
            Adquira o Seguro de Garantia Estendida Original para o seu produto
          </h5>
          <p>
            É muito fácil! Você pode adquirir a garantia estendida comprando em
            conjunto com um produto que você deseja, dessa forma, você terá
            maior cobertura para possíveis defeitos funcionais que seu produto
            possa ter.
          </p>
          <p>
            Para garantir o Seguro Garantia Estendida junto com a sua compra
            atual basta escolher o produto que você deseja, e lá estarão
            disponíveis os planos que podem ser contratados para o produto. Após
            a escolha do plano, basta preencher o formulário com os dados
            solicitados e realizar o pagamento, lembrando que o valor do seguro
            contratado será incluso no valor do produto que está sendo comprado.
            Portanto, após o término da garantia de fábrica do produto, o Seguro
            Garantia Estendida Original começa a valer imediatamente.
          </p>
          <p>
            A Império Promotora de Vendas S.A. sob CNPJ 28.430.631/0001-60, site
            lojasimpério.com.br, sob sede na ROD BR 101 Norte, 5800, sala 02,
            Artur Lundgren II, Paulista, PE, CEP 53.416-710, é Representante de
            Seguros da Safra Seguros Gerais e está autorizada a comercializar o
            seguro de Garantia Estendida Original Ramo 0195. Processo SUSEP
            15414.607848/2021-11. Consulte as Condições Gerais do Produto antes
            da contratação.
          </p>
          <p>
            O registro desse plano na SUSEP, não implica, por parte da
            autarquia, incentivo ou recomendação à sua comercialização. Este
            seguro é garantido pela Safra Seguros Gerais S.A, sob o número
            06.109.373/0001-81 com sede no município e estado de São Paulo, na
            Av. Paulista, nº 2.100, Bela Vista, CEP 01310-930. www.safra.com.br.
            Inscrição SUSEP 01627. A CONTRATAÇÃO DO SEGURO É OPCIONAL, COM A
            DEVOLUÇÃO INTEGRAL DOS VALORES PARA CANCELAMENTOS REALIZADOS EM ATÉ
            7 (SETE) DIAS DA CORRIDOS DA CONTRATAÇÃO. É proibido condicionar
            desconto no preço do bem à aquisição do seguro.
          </p>
          <p>
            A comercialização do seguro é fiscalizada pela SUSEP:
            www.susep.gov.br – 0800 021 8484.
          </p>
          <p>
            Garantia Estendida Original Safra – É a extensão de garantia
            original, cuja vigência inicia-se imediatamente após o término da
            garantia do fornecedor e contempla as mesmas coberturas e exclusões
            oferecidas pela garantia do fornecedor.
          </p>
          <p>Consulte os canais de atendimento</p>
          <p>
            Abertura de Sinistro: 0800 770 4202
            <br />
            Segunda a Sábado das 08hs às 20hs
            <br />
            SAC – Serviço de Atendimento ao Cliente: 0800 772 5755
            <br />
            24 horas, 7 dias por semana
            <br />
            Deficientes Auditivos ou de Fala: 0800 772 4136
            <br />
            24 horas, 7 dias por semana
            <br />
            Ouvidoria: 0800 770 1236
            <br />
            Segunda a Sexta das 09hs às 18hs
            <br />
            Deficientes Auditivos ou de Fala: 0800 727 7555
            <br />
            Segunda a Sexta das 09hs às 18hs
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};
