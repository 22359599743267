import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';
import App from './App';
import 'dotenv/config';

Sentry.init({
  dsn: process.env.SENTRY_DNS,
  environment: process.env.SENTRY_ENV,
});

TagManager.initialize({
  gtmId: 'GTM-NBLDLWC',
  dataLayerName: 'PageDataLayer',
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
