export function cpfIsValid(cpf: string): boolean {
  let soma;
  let resto;
  soma = 0;
  const strCPF = cpf.replace(/\D/g, '');
  if (strCPF === '00000000000') return false;
  if (strCPF === '11111111111') return false;
  if (strCPF === '22222222222') return false;
  if (strCPF === '33333333333') return false;
  if (strCPF === '44444444444') return false;
  if (strCPF === '55555555555') return false;
  if (strCPF === '66666666666') return false;
  if (strCPF === '77777777777') return false;
  if (strCPF === '88888888888') return false;
  if (strCPF === '99999999999') return false;

  for (let i = 1; i <= 9; i += 1)
    soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i); // eslint-disable-line
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(9, 10))) return false; // eslint-disable-line

  soma = 0;
  for (let i = 1; i <= 10; i += 1)
    soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i); // eslint-disable-line
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(10, 11))) return false; // eslint-disable-line
  return true;
}

export function emailIsValid(email: string): boolean {
  if (email.indexOf('@') === -1) {
    return false;
  }
  const after = email.split('@');
  if (after[1].indexOf('.') === -1) {
    return false;
  }

  return true;
}
