import React, { useRef, useState } from 'react';

import api from '~/services/api';
import Modal from '~/components/Modal';
import Input from '~/components/Input';
import { normalizePhone } from '~/utils/normalize';

import ReCAPTCHA from 'react-google-recaptcha';
import TagManager from 'react-gtm-module';

interface IProps {
  cancelModalAutentication: () => void; // eslint-disable-line
  getSubmitedDataAuthentication: (token: string) => void; // eslint-disable-line
}

const Authentication: React.FC<IProps> = ({
  getSubmitedDataAuthentication,
  cancelModalAutentication,
}) => {
  const reRef = useRef<any>(null);
  const [authStep, setAuthStep] = useState<string>('');
  const [authEmail, setAuthEmail] = useState<string>('');
  const [authPhone, setAuthPhone] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>('');

  async function handleSubmitAuthPhone() {
    const gResponse = await reRef?.current?.executeAsync();
    reRef?.current?.reset();

    if (!gResponse) {
      setError('Erro, atualize a tela e tente novamente');
      window.location.href = '#error__message';
      return;
    }
    try {
      setError('');
      await api.post(`auth/customer/phone?complexity=6`, {
        phone: authPhone.replace(/[^\d]/g, ''),
        gResponse,
      });
      try {
        TagManager.dataLayer({
          dataLayerName: 'PageDataLayer',
          dataLayer: {
            event: 'login',
            method: authPhone.replace(/[^\d]/g, ''),
          },
        });
      } catch (err) {
        //
      }
      setAuthStep('authPhoneCode');
    } catch (err) {
      setError(err.response.data.message);
    }
  }

  async function handleSubmitAuthEmail() {
    const gResponse = await reRef?.current?.executeAsync();
    reRef?.current?.reset();

    if (!gResponse) {
      setError('Erro, atualize a tela e tente novamente');
      window.location.href = '#error__message';
      return;
    }
    try {
      setError('');
      await api.post(`auth/customer/email?complexity=6`, {
        email: authEmail,
        gResponse,
      });
      try {
        TagManager.dataLayer({
          dataLayerName: 'PageDataLayer',
          dataLayer: {
            event: 'login',
            method: authEmail,
          },
        });
      } catch (err) {
        //
      }
      setAuthStep('authEmailCode');
    } catch (err) {
      setError(err.response.data.message);
    }
  }

  async function handleSubmitAuthCodePhone() {
    const gResponse = await reRef?.current?.executeAsync();
    reRef?.current?.reset();

    if (!gResponse) {
      setError('Erro, atualize a tela e tente novamente');
      window.location.href = '#error__message';
      return;
    }
    try {
      setError('');
      const { data } = await api.post(`authentication/customer/phone`, {
        phone: authPhone.replace(/[^\d]/g, ''),
        code,
        gResponse,
      });
      setAuthStep('');
      getSubmitedDataAuthentication(data.token);
    } catch (err) {
      setError(err.response.data.message);
    }
  }

  async function handleSubmitAuthCodeEmail() {
    const gResponse = await reRef?.current?.executeAsync();
    reRef?.current?.reset();

    if (!gResponse) {
      setError('Erro, atualize a tela e tente novamente');
      window.location.href = '#error__message';
      return;
    }
    try {
      setError('');
      const { data } = await api.post(`authentication/customer/email`, {
        email: authEmail,
        code,
        gResponse,
      });
      setAuthStep('');
      getSubmitedDataAuthentication(data.token);
    } catch (err) {
      setError(err.response.data.message);
    }
  }

  return (
    <Modal size="big">
      <button
        type="button"
        className="close"
        onClick={() => cancelModalAutentication()}
      >
        <span aria-hidden="true">×</span>
      </button>
      {error !== '' && <p className="alert alert-danger mb-3">{error}</p>}
      {authStep === '' && (
        <div className="text-center">
          <p>Faça o login em sua conta</p>
          <div>
            <button
              className="btn btn-imperio"
              onClick={() => setAuthStep('authEmail')}
              type="button"
            >
              <i className="mr-2 fas fa-envelope" />
              Usar Email
            </button>
            <button
              className="btn btn-imperio ml-2"
              onClick={() => setAuthStep('authPhone')}
              type="button"
            >
              <i className="mr-2 fas fa-phone" />
              Usar Celular
            </button>
          </div>
        </div>
      )}
      {authStep === 'authEmail' && (
        <div>
          <p className="text-center">Quero entrar usando meu email</p>
          <Input
            label="Email"
            name="email"
            required
            onChange={e => setAuthEmail(String(e.value))}
          />
          <button
            className="btn btn-imperio mt-4"
            type="button"
            onClick={() => handleSubmitAuthEmail()}
          >
            Entrar
          </button>
        </div>
      )}
      {authStep === 'authPhone' && (
        <div>
          <p className="text-center">Quero entrar usando meu celular</p>
          <Input
            label="Celular"
            name="phone"
            required
            normalize={normalizePhone}
            onChange={e => setAuthPhone(normalizePhone(String(e.value)))}
          />
          <button
            className="btn btn-imperio mt-4"
            type="button"
            onClick={() => handleSubmitAuthPhone()}
          >
            Entrar
          </button>
        </div>
      )}
      {authStep === 'authPhoneCode' && (
        <div>
          <p className="text-center">
            Enviamos um código via SMS para o seu celular. Digite o Código nos
            campos abaixo para ter acesso a sua conta.
          </p>
          <Input
            label="Código"
            name="code"
            required
            onChange={e => setCode(String(e.value))}
          />
          <div className="align-items-center d-flex flex-column">
            <button
              className="btn btn-imperio mt-4"
              type="button"
              onClick={() => handleSubmitAuthCodePhone()}
            >
              Enviar código
            </button>
            <button
              className="btn btn-link mt-3"
              type="button"
              onClick={() => handleSubmitAuthPhone()}
            >
              Não recebeu o SMS? Tente novamente
            </button>
          </div>
        </div>
      )}
      {authStep === 'authEmailCode' && (
        <div>
          <p className="text-center">
            Enviamos um código para o seu email. Digite o Código nos campos
            abaixo para ter acesso a sua conta.
          </p>
          <Input
            label="Código"
            name="code"
            required
            onChange={e => setCode(String(e.value))}
          />
          <div className="align-items-center d-flex flex-column">
            <button
              className="btn btn-imperio mt-4"
              type="button"
              onClick={() => handleSubmitAuthCodeEmail()}
            >
              Enviar código
            </button>
            <button
              className="btn btn-link mt-3"
              type="button"
              onClick={() => handleSubmitAuthEmail()}
            >
              Não recebeu o email? Tente novamente
            </button>
          </div>
        </div>
      )}
      <ReCAPTCHA
        sitekey="6Lf8NtUaAAAAAEJyuqWiJAo9K4-y_2KqBfP6A_VM"
        size="invisible"
        ref={reRef}
      />
    </Modal>
  );
};

export default Authentication;
